import {gql, useMutation, useQuery} from '@apollo/client'
import React, {useState} from 'react'
import {toTitleCase} from '../../common'
import {PlusCircleIconSmall, Skeleton} from '../components'
import Page from '../components/page'
import {
  PageHeading,
  SectionHeading,
  Panel,
  Button,
  Table,
  ModalProps,
  Modal,
  FormInput,
  FormCombobox,
  Banner,
  ConfirmDialog,
} from '../components/tailwind'
import {SettingsAddRuleMutation} from '../graphql/SettingsAddRuleMutation'
import {SettingsDeleteRuleMutation} from '../graphql/SettingsDeleteRuleMutation'
import {SettingsRulesQuery} from '../graphql/SettingsRulesQuery'
import {getStatusDisplay, Rule, RuleColor, Status} from '../models'

const GET_RULES = gql`
  query SettingsRulesQuery {
    rules {
      id
      threshold
      color
      status
    }
  }
`

export const ADD_RULE = gql`
  mutation SettingsAddRuleMutation($threshold: Int!, $color: RuleColor!, $status: Status) {
    addRule(threshold: $threshold, color: $color, status: $status) {
      id
      threshold
      color
      status
    }
  }
`

export const UPDATE_RULE = gql`
  mutation SettingsUpdateRuleMutation($id: ID!, $threshold: Int!, $color: RuleColor!, $status: Status!) {
    updateRule(id: $id, threshold: $threshold, color: $color, status: $status) {
      id
      threshold
      color
      status
    }
  }
`

export const DELETE_RULE = gql`
  mutation SettingsDeleteRuleMutation($id: ID!) {
    deleteRule(id: $id)
  }
`

interface RuleEditorProps extends ModalProps {
  rule?: Rule
}

const RuleEditor: React.FC<RuleEditorProps> = ({rule, onDismiss}) => {
  const colors = [
    {value: RuleColor.RED, display: 'Red'},
    {value: RuleColor.YELLOW, display: 'Yellow'},
    {value: RuleColor.GREEN, display: 'Green'},
  ]

  const statuses = [
    {value: Status.PENDING, display: getStatusDisplay(Status.PENDING)},
    {value: Status.SEATED, display: getStatusDisplay(Status.SEATED)},
    {value: Status.WAITING, display: getStatusDisplay(Status.WAITING)},
    {value: Status.CLAIMED, display: getStatusDisplay(Status.CLAIMED)},
  ]

  const [thresholdInput, setThresholdInput] = useState<number>(Math.floor((rule?.threshold ?? 0) / 60))
  const [colorInput, setColorInput] = useState(colors.find((color) => color.value === rule?.color) ?? colors[0])
  const [statusInput, setStatusInput] = useState(
    statuses.find((status) => status.value === rule?.status) ?? statuses[0]
  )

  const [thresholdError, setThresholdError] = useState<string>()
  const [colorError, setColorError] = useState<string>()
  const [statusError, setStatusError] = useState<string>()

  const [addRule, {loading: adding, error: addError}] = useMutation<SettingsAddRuleMutation>(ADD_RULE)
  const [updateRule, {loading: updating, error: updateError}] = useMutation(UPDATE_RULE)
  const error = addError || updateError
  const busy = adding || updating

  const handleSave = async (e: React.FormEvent | React.MouseEvent) => {
    e.preventDefault()

    setThresholdError(undefined)
    setColorError(undefined)
    setStatusError(undefined)

    let hasError = false

    if (thresholdInput !== 0 && !thresholdInput) {
      setThresholdError('Threshold is required')
      hasError = hasError || true
    }

    if (!colorInput) {
      setColorError('Color is required')
      hasError = hasError || true
    }

    if (hasError) {
      return
    }

    const threshold = thresholdInput * 60
    const color = colorInput.value
    const status = statusInput?.value

    if (rule) {
      await updateRule({
        variables: {id: rule.id, threshold, color, status},
      })
    } else {
      await addRule({variables: {threshold, color, status}})
    }

    onDismiss()
  }

  const handleThresholdChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setThresholdInput(Number.parseInt(e.target.value, 10))
  }

  const handleColorChanged = (color: {value: RuleColor; display: string}) => {
    setColorInput(color)
  }

  const handleStatusChanged = (status: {value: Status; display: string}) => {
    setStatusInput(status)
  }

  return (
    <Modal onDismiss={onDismiss}>
      <SectionHeading title={`${rule ? 'Edit' : 'Add'} Rule`} />
      <form autoComplete="off" className="mt-3" onSubmit={handleSave}>
        <div className="space-y-4">
          <FormInput
            required
            autoFocus
            type="number"
            label="Threshold (Minutes)"
            value={thresholdInput}
            error={thresholdError}
            onChange={handleThresholdChanged}
          />

          <FormCombobox
            required
            label="Color"
            value="value"
            display="display"
            initial={colorInput}
            items={colors}
            error={colorError}
            onSelection={handleColorChanged}
          />

          <FormCombobox
            label="Status"
            value="value"
            display="display"
            initial={statusInput}
            items={statuses}
            error={statusError}
            onSelection={handleStatusChanged}
          />
        </div>

        <div className="mt-8 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <Button fullWidth className="sm:col-start-2" busy={busy} disabled={busy} type="submit" onClick={handleSave}>
            Save
          </Button>
          <Button fullWidth secondary className="mt-3 sm:mt-0 sm:col-start-1" onClick={onDismiss}>
            Cancel
          </Button>
        </div>

        {error && <Banner type="error" content={error.message} className="mt-3" />}
      </form>
    </Modal>
  )
}

const SettingsPage: React.FunctionComponent = () => {
  const {data, loading, refetch} = useQuery<SettingsRulesQuery>(GET_RULES)
  const [deleteRule] = useMutation<SettingsDeleteRuleMutation>(DELETE_RULE)

  const [ruleToDelete, setRuleToDelete] = useState<Rule>()
  const [ruleToEdit, setRuleToEdit] = useState<Rule>()
  const [showAddRule, setShowAddRule] = useState<boolean>()

  const rules: Rule[] = data?.rules
    ?.slice()
    .sort((a, b) => (a.status === b.status ? a.threshold - b.threshold : a.status?.localeCompare(b.status)))

  const handleAddRule = () => {
    setShowAddRule(true)
  }

  const handleAddRuleDismissed = () => {
    refetch()
    setShowAddRule(false)
  }

  const handleEditRule = (rule: Rule) => {
    setRuleToEdit(rule)
  }

  const handleEditRuleDismissed = () => {
    setRuleToEdit(undefined)
  }

  const handleDeleteRule = (rule: Rule) => {
    setRuleToDelete(rule)
  }

  const handleDeleteConfirmation = async (result: boolean) => {
    setRuleToDelete(undefined)

    if (result) {
      await deleteRule({variables: {id: ruleToDelete.id}})
      refetch()
    }
  }

  if (loading) {
    return (
      <Page title="Settings">
        <Skeleton />
      </Page>
    )
  }

  return (
    <Page title="Settings">
      <PageHeading title="Settings" />

      <Panel>
        <SectionHeading title="Rules" divider={false}>
          <Button size="small" icon={PlusCircleIconSmall} className="mt-3 md:mt-0" onClick={handleAddRule}>
            Add Rule
          </Button>
        </SectionHeading>

        {rules && (
          <Table
            items={rules}
            columns={[
              {name: 'Status', value: (r) => getStatusDisplay(r.status)},
              {name: 'Threshold', value: (r) => `${r.threshold / 60} min`},
              {name: 'Color', value: (r) => toTitleCase(r.color)},
              {
                value: (rule) => (
                  <div className="flex justify-end space-x-2 whitespace-nowrap text-right text-sm leading-5">
                    <a
                      className="font-medium text-indigo-600 hover:text-indigo-900 cursor-pointer"
                      onClick={() => handleEditRule(rule)}
                    >
                      Edit
                    </a>
                    <a
                      className="font-medium text-indigo-600 hover:text-indigo-900 cursor-pointer"
                      onClick={() => handleDeleteRule(rule)}
                    >
                      Delete
                    </a>
                  </div>
                ),
              },
            ]}
          />
        )}

        {ruleToDelete && (
          <ConfirmDialog title="Confirm Delete" onDismiss={handleDeleteConfirmation}>
            <h1 className="text-gray-700">Are you sure you want to delete this rule?</h1>
            <Banner type="error" content="This action cannot be undone" />
          </ConfirmDialog>
        )}

        {showAddRule && <RuleEditor onDismiss={handleAddRuleDismissed} />}
        {ruleToEdit && <RuleEditor rule={ruleToEdit} onDismiss={handleEditRuleDismissed} />}
      </Panel>
    </Page>
  )
}

export default SettingsPage
